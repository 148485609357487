/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'swiper/css/bundle';

*{
  // --pdf-height: calc(100vh - 34px - 42px);
  --pdf-height: 590px;

}

.details-modal{
  --width: 100%;
  --min-height: 100%;
  align-items: flex-end;
  --backdrop-opacity: 1;
}

ion-backdrop{
background: #00000030;
}

#viewerContainer{
  margin-left: auto;
  margin-right: auto; ;
  width: calc(var(--pdf-height)/3*4);
  height: var(--pdf-height);
  margin-bottom: 1rem;
}

#mainContainer{
  height: 100%;
}

#outerContainer{
  height: 100%;
}

ngx-extended-pdf-viewer{
  height:  100%;
}

.normal-modal {
  --width: 100% !important;
  --min-height: 100% !important;
  align-items: flex-end;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

// .sc-ion-modal-ios-h {
//   --width: 100% !important;
//   --min-height: 100% !important;
//   align-items: flex-end;
//   --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
// }

// .sc-ion-modal-ios-h:first-of-type {
//   --width: 100% !important;
//   --min-height: 100% !important;
//   align-items: flex-end;
//   --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
// }

// #documentPropertiesDialog{
//   display: none !important;
// }

// #documentProperties{
//   display: none !important;
// }

// #dialogContainer{
//   display: none !important;
// }

i.fa {
  -webkit-animation: show 1s 1;
  /* any other properties to override from FontAwesome's default .fa rule */
}
@-webkit-keyframes show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.two-numbers {
  width: 45px;
  text-align: center;
}

.three-numbers {
  width: 60px;
  text-align: center;
}

.four-numbers {
  width: 60px;
  text-align: center;
}

.seven-numbers {
  width: 100px;
  text-align: center;
}

.details-modal-casa{
  --border-radius: 30px;
  --width: 90vw !important;
  --height: 90vh !important;
  align-items: center;
}

.details-modal-studio{
  --border-radius: 30px;
  --width: 90% !important;
  --height: 85% !important;
  align-items: center;
}
